export type Deck = CardType[];
export interface CardType {
  id: string;
  value: string;
  suit: Suit;
  className?: string;
}
export enum Suit {
  HEARTS = `Hearts`,
  DIAMONDS = `Diamonds`,
  SPADES = `Spades`,
  CLUBS = `Clubs`,
}

import { Suit, Deck, CardType } from './types';

export const getNewDeck = (): Deck => {
  // TODO: add types package for FE and BE to share
  const suits = [Suit.HEARTS, Suit.CLUBS, Suit.DIAMONDS, Suit.SPADES];
  const vals = [`A`, 2, 3, 4, 5, 6, 7, 8, 9, 10, `J`, `Q`, `K`].map(String);
  const cards: Deck = vals.reduce((newDeck: Deck, value: string) => {
    const suitCards: CardType[] = suits.map(
      (suit: Suit): CardType => ({ id: `${suit}${value}`, suit, value })
    );
    return [...newDeck, ...suitCards];
  }, []);
  return cards;
};

export const getRandomHand = (newHand: CardType[], newDeck: Deck): CardType[][] => {
  if (!newDeck.length || newHand.length === 5) return [newHand, newDeck];

  const randomIndex = Math.floor(Math.random() * newDeck.length);
  const card = newDeck[randomIndex];
  return getRandomHand(
    [...newHand, card],
    newDeck.filter((x) => x.id !== card.id)
  );
};

import club from '../assets/Clover.svg';
import diamond from '../assets/Diamond.svg';
import heart from '../assets/Heart.svg';
import spade from '../assets/Spade.svg';
import { Suit } from '../types'; // eslint-disable-line import/no-unresolved

const SuitImg = ({ suit }: { suit: Suit }) => {
  const className = `w-20`;
  switch (suit) {
    case Suit.HEARTS:
      return <img className={className} alt="heart" src={heart} />;
    case Suit.CLUBS:
      return <img className={className} alt="clover" src={club} />;
    case Suit.SPADES:
      return <img className={className} alt="spade" src={spade} />;
    case Suit.DIAMONDS:
      return <img className={className} alt="diamond" src={diamond} />;
    default:
      console.error(`Invalid suit: ${suit}`);
      return <span />;
  }
};

const getFontColor = (suit: Suit) => {
  switch (suit) {
    case Suit.HEARTS:
    case Suit.DIAMONDS:
      return `text-red-500`;
    default:
      return ``;
  }
};

interface CardProps {
  value: string;
  suit: Suit;
}
export function Card({ value, suit }: CardProps) {
  return (
    <div className="grid grid-rows-cardlayout gap-1 bg-white p-4 rounded-xl w-40 h-52 relative">
      <span className={`text-6xl font-bold flex justify-start ${getFontColor(suit)}`}>{value}</span>
      <div className="w-9">
        <SuitImg suit={suit} />
      </div>
      <div className="flex justify-end">
        <SuitImg suit={suit} />
      </div>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { CardType } from '../types'; // eslint-disable-line import/no-unresolved
import { Card } from './Card';

const cardClasses: string[] = [
  'absolute -top-64 transition-all duration-300 lg:rotate-15 lg:translate-y-102 lg:-translate-x-102 translate-y-100 translate-x-48',
  'absolute -top-64 transition-all duration-300 delay-75 lg:rotate-7p5 lg:translate-y-112 lg:-translate-x-52 translate-y-100 -translate-x-48',
  'absolute -top-64 transition-all duration-300 delay-100 lg:translate-y-116 translate-y-100',
  'absolute -top-64 transition-all duration-300 delay-150 lg:rotate-n7p5 lg:translate-y-112 lg:translate-x-52 translate-y-160 -translate-x-24',
  'absolute -top-64 transition-all duration-300 delay-200 lg:rotate-n15 lg:translate-y-102 lg:translate-x-102 translate-y-160 translate-x-24',
];

interface CardProps {
  cards: CardType[];
}
export function Hand({ cards }: CardProps) {
  const [hand, setHand] = useState(cards);

  useEffect(() => {
    setHand(cards.map((x) => ({ ...x, className: `absolute -top-64` })));
    setTimeout(() => {
      if (cards.length === 2) {
        const styles =
          'absolute -top-64 transition-all duration-300 translate-y-116 -translate-x-32';
        setHand(
          cards.map((x, i) => ({
            ...x,
            className: `${styles} ${i === 0 ? '-' : ''}translate-x-32`,
          }))
        );
      } else {
        setHand(
          cards.map((x, i) => {
            return { ...x, className: cardClasses[i] };
          })
        );
      }
    }, 300);
  }, [cards]);

  return (
    <div className="flex justify-evenly">
      {hand.map((card, index) => {
        return (
          <div key={index} className={card.className}>
            <Card value={card.value} suit={card.suit} />
          </div>
        );
      })}
    </div>
  );
}

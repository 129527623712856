export function EndGameText({ text }: { text: string }) {
  const textParts = text.split(`.`);
  return (
    <div className="flex flex-col items-center">
      {textParts.map((txt: string, i: number) => (
        <div key={`${txt}${i}`} className="text-white text-2xl">
          {txt}
          {i !== textParts.length - 1 ? `. ` : ``}
        </div>
      ))}
    </div>
  );
}

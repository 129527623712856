import { useEffect, useState } from 'react';
import { DealButton, Hand, Counter, ResetButton, EndGameText, WinnerBanner } from './components';
import { CardType, Deck } from './types';
import { getNewDeck, getRandomHand } from './utils';
import styles from './App.module.css';

function App() {
  const [deck, setDeck] = useState<Deck>([]);
  const [hand, setHand] = useState<CardType[]>([]);
  const [endGameText, setEndGameText] = useState<string>(``);

  // TODO: use useMemo
  useEffect(() => {
    const cards = getNewDeck();
    setDeck(cards);
  }, []);

  const aceRemaining = (x: CardType) => x.value === `A`;
  const dealNextHand = () => {
    const [newHand, newDeck] = getRandomHand([], deck);
    setHand(newHand);
    setDeck(newDeck);
    if (!newDeck.find(aceRemaining)) {
      setEndGameText(`You lose. Better luck next time!`);
    }
    if (newHand.length === 2) {
      setTimeout(() => {
        setEndGameText(`You Win!`);
      }, 600);
    }
  };
  const reset = () => {
    setDeck(getNewDeck());
    setHand([]);
    setEndGameText(``);
  };
  return (
    <div className={`${styles.body} grid grid-rows-pagelayout`}>
      <div className="flex justify-center">
        <Counter count={deck.length} text="Cards Left" />
        <Counter count={deck.filter(aceRemaining).length} text="Aces Left" />
      </div>
      {endGameText === `You Win!` && (
        <div className="absolute top-24 w-full z-10">
          <div className="flex justify-center items-center">
            <WinnerBanner />
          </div>
        </div>
      )}
      <Hand cards={hand} />
      <EndGameText text={endGameText} />
      {/* </div><div className="absolute bottom-10 w-full"> */}
      <div className="w-full">
        <div className="m-auto">
          {deck.find(aceRemaining) && endGameText === `` && <DealButton onClick={dealNextHand} />}
          <ResetButton onClick={reset} />
        </div>
      </div>
    </div>
  );
}

export default App;

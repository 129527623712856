import BannerBody from '../assets/BannerBody.svg';
import BannerLeft from '../assets/BannerLeft.svg';
import BannerRight from '../assets/BannerRight.svg';
import RectangleLeft from '../assets/RectangleLeft.svg';
import RectangleRight from '../assets/RectangleRight.svg';

export function WinnerBanner() {
  return (
    <div className="relative lg:w-196 w-116">
      <img
        alt="banner left"
        className="absolute lg:top-6 top-5 lg:-left-32 -left-18 lg:h-auto h-18"
        src={BannerLeft}
      />
      <img
        alt="left rectangle"
        className="absolute lg:top-6 top-5 left-0 lg:h-auto h-18"
        src={RectangleLeft}
      />
      <img
        alt="banner right"
        className="absolute lg:top-6 top-5 lg:-right-32 -right-18 lg:h-auto h-18"
        src={BannerRight}
      />
      <img
        alt="right rectangle"
        className="absolute lg:top-6 top-5 right-0 lg:h-auto h-18"
        src={RectangleRight}
      />
      <img alt="banner body" className="absolute top-0 lg:left-0 lg:h-auto" src={BannerBody} />
      <span className="lg:text-8xl text-5xl uppercase absolute lg:inset-x-40 inset-x-20 top-4 font-bold text-white">
        Winner!
      </span>
    </div>
  );
}
